import React, { useMemo } from 'react'
import { useTable } from 'react-table'
import MOCK_DATA from './MOCK_DATA.json'
import { COLUMNS } from './columns'
import './table.css' 
// import ReactTable from "react-table-6";  
// import "react-table-6/react-table.css" 
const BasicTable = () => {
    const columns= useMemo(()=>COLUMNS,[])
    const data=useMemo(()=>MOCK_DATA,[])
    const TableInstance=useTable({
        columns,
        data
    })
    const {getTableProps,getTableBodyProps,headerGroups,rows,prepareRow}=TableInstance
  return (
    <table{...getTableProps()}>
        <thead>
            {
                headerGroups.map(headerGroup=>(
                    <tr{...headerGroup.getHeaderGroupProps()}>
                    {
                        headerGroup.headers.map(column=>(
                            <th{...column.getHeaderProps()}>{column.render('Header')}</th>
                        ))
                    }
                       
                    </tr>
                ))
            }
             
        </thead>
        <tbody{...getTableBodyProps()}>
        {
            rows.map(row =>{
                prepareRow(row)
                return(
                   <tr{...row.getRowProps()}>
                      {
                           row.cells.map(cell =>{
                             return <td{...cell.getCellProps()}>{cell.render('Cell')}</td>
                           })
                      }
                      
                   </tr>
                )
            })
        }
           
        </tbody>
      
    </table>
    )
    }

export default BasicTable
